import React from 'react';
import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import Spinner from '@audacious/components/components/Spinner';
import TextInput from '@audacious/components/components/TextInput';
import IconButton from '@audacious/components/components/IconButton';
import { Row, Column } from '@audacious/components/components/Grid';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faPrint } from '@audacious/icons/solid/faPrint';
import { Text } from '@audacious/components/components/Typography';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { localizationShape } from '../../../../prop-types/localization';
import MedicationsResultsTable from './medications-results-table';
import searchFilter from '../../../../../common/util/search-filter';
import QueryType from '../../../../../common/query/query-type';
import printMedications from '../../../../../common/util/print-medications';

import './medications-results-panel.scss';

class MedicationsResultsPanel extends React.Component {
    constructor(...args) {
        super(...args);

        this.handleSearch = this.handleSearch.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.state = {
            searchInput: '',
        };

        this.tableRef = React.createRef();
    }

    handleSearch(searchInput) {
        this.setState({ searchInput });
    }

    handlePrintClick() {
        if (!this.tableRef.current) {
            return;
        }

        const sortedItems = this.tableRef.current.getSortedMedications();

        const { patientFields, userName, receivedDateTime } = this.props;

        printMedications({
            medications: sortedItems,
            userName,
            patientFields,
            receivedDateTime,
        });
    }

    render() {
        const {
            localization,
            medications,
            loading,
            delayed,
            timedOut,
            receivedDateTime,
            surescriptsErrorDescription,
            surescriptsErrorCode,
            isError,
            allowPrint,
        } = this.props;
        const { searchInput } = this.state;

        let matchingMeds = medications;

        // Only show documents matching the user entered search
        if (!isEmpty(trim(searchInput))) {
            matchingMeds = searchFilter(medications, searchInput, [
                { path: 'name' },
                { path: 'sig.0.SigText' },
                { path: 'fillDate', isDate: true },
                { path: 'writtenDate', isDate: true },
                { path: 'quantity', isInteger: true },
                { path: 'days', isInteger: true },
                { path: 'refills', isInteger: true },
                { path: 'prescriber.0.Item.Name.FirstName' },
                { path: 'prescriber.0.Item.Name.MiddleName' },
                { path: 'prescriber.0.Item.Name.LastName' },
                { path: 'pharmacy.0.BusinessName' },
            ]);
        }

        if (!loading && timedOut) {
            return (
                <div className="med-results-info ">
                    <Text size="lg" weight="bold">
                        Unable to complete request. Please try again.
                    </Text>
                </div>
            );
        }

        if (
            !loading &&
            isError &&
            (surescriptsErrorCode || surescriptsErrorDescription)
        ) {
            return (
                <div className="med-results-info ">
                    <Text size="lg" weight="bold">
                        We&#x27;re unable to retrieve medications. Reason:{' '}
                        {surescriptsErrorCode} &#45;{' '}
                        {surescriptsErrorDescription}
                    </Text>
                </div>
            );
        }

        if (
            !loading &&
            isError &&
            !surescriptsErrorCode &&
            !surescriptsErrorDescription
        ) {
            return (
                <div className="med-results-info ">
                    <Text size="lg" weight="bold">
                        We&#x27;re unable to retrieve medications at this time.
                        Please try searching again.
                    </Text>
                </div>
            );
        }

        if (!loading && !medications.length) {
            return (
                <div className="med-results-info">
                    <Text size="lg" weight="bold">
                        No medications found using the patient information provided. Complete additional fields if possible, then click Search Patient again.
                    </Text>
                </div>
            );
        }

        const printButtonRender = allowPrint ? (
            <IconButton
                id="medications-print-button"
                className="medications-print-button"
                icon={faPrint}
                size="sm"
                onClick={this.handlePrintClick}
                aria-label="Print"
            />
        ) : null;

        return (
            <>
                {medications.length > 0 ? (
                    <>
                        <PageContainerGroup className="medication-results-header">
                            <Row gutter="16" enableAfter>
                                <Column width={[null, '8']}>
                                    <div>
                                        <Text color="success" size="md">
                                            {`Data from Surescripts received on - ${receivedDateTime}`}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text>
                                            Certain medications may be missing
                                            from the list below. You should
                                            independently verify medication
                                            history with the patient.
                                        </Text>
                                    </div>
                                </Column>
                                <Column
                                    width={[null, 4]}
                                    className="medication-controls-column"
                                >
                                    <TextInput
                                        className="medications-search"
                                        id="searchDocument"
                                        value={searchInput}
                                        placeholder="Search..."
                                        aria-label="Search Documents"
                                        leftIcon={{
                                            icon: faMagnifyingGlass,
                                        }}
                                        onChange={this.handleSearch}
                                        size="sm"
                                    />
                                    {printButtonRender}
                                </Column>
                            </Row>
                        </PageContainerGroup>
                        <MedicationsResultsTable
                            ref={this.tableRef}
                            localization={localization.table}
                            items={matchingMeds}
                        />
                        {matchingMeds.length === 0 ? (
                            <div>
                                <Text color="danger">
                                    No matching medications!
                                </Text>
                            </div>
                        ) : null}
                    </>
                ) : null}
                {loading ? (
                    <div id="query-document-spinner" className="results-info">
                        <Spinner size="xs" />
                        {!delayed
                            ? ' Retrieving results...'
                            : ' Medications search still in process.'}
                    </div>
                ) : null}
            </>
        );
    }
}

MedicationsResultsPanel.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    medications: PropTypes.arrayOf(PropTypes.any),
    receivedDateTime: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    delayed: PropTypes.bool.isRequired,
    timedOut: PropTypes.bool.isRequired,
    localization: PropTypes.shape(localizationShape.documentResults),
    surescriptsErrorCode: PropTypes.string,
    surescriptsErrorDescription: PropTypes.string,
    isError: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    patientFields: PropTypes.objectOf(PropTypes.any).isRequired,
    userName: PropTypes.string.isRequired,
    allowPrint: PropTypes.bool.isRequired,
};

MedicationsResultsPanel.defaultProps = {
    medications: null,
    receivedDateTime: null,
    localization: null,
    surescriptsErrorCode: null,
    surescriptsErrorDescription: null,
    isError: false,
};

export default connectToStores(
    MedicationsResultsPanel,
    [
        'MedicationsResultsStore',
        'QueryStore',
        'PatientFormStore',
        'Session',
        'EpraPreferencesStore',
    ],
    context => {
        const medicationsResultsStore = context.getStore(
            'MedicationsResultsStore',
        );
        const localizationStore = context.getStore('LocalizationStore');
        const queryStore = context.getStore('QueryStore');

        const patientFormStore = context.getStore('PatientFormStore');
        const patientFields = patientFormStore.getFields();

        const sessionStore = context.getStore('Session');
        const user = sessionStore.getUser();
        const userName = isNil(user) ? '' : user.getFullName();

        const epraPreferencesState = context
            .getStore('EpraPreferencesStore')
            .getState();

        const allowPrint = get(
            epraPreferencesState,
            'preferences.allowPrint',
            false,
        );

        return {
            medications: medicationsResultsStore.getMedications(),
            surescriptsErrorCode: medicationsResultsStore.getErrorCode(),
            surescriptsErrorDescription: medicationsResultsStore.getErrorDescription(),
            receivedDateTime: medicationsResultsStore.getReceivedDateTime(),
            localization: localizationStore.getState().medicationsResults,
            isError: medicationsResultsStore.isError(),
            loading: queryStore.isTypeLoading(QueryType.MEDICATIONS),
            delayed: queryStore.isDelayed(QueryType.MEDICATIONS),
            timedOut: queryStore.isTimedOut(QueryType.MEDICATIONS),
            patientFields,
            userName,
            allowPrint,
        };
    },
);
